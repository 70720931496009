<template>
  <ContentBlock>
    <div class="subpage__title heading-3-sb grey-100">
      {{ $t('Web.Project.Label.Archive') }}

      <SlSelectionInfo
        v-if="selectedRowIds.length"
        @shortcut:delete="handleProjectDelete"
      >
        <template #description>
          {{ $t('Web.Selection', { 1: selectedRowIds.length }) }}
        </template>
        <template #actions>
          <SlButton
            variant="text"
            color="grey"
            @click="resetSelected"
          >
            <template #prepend>
              <icon
                class="fill-off size-20 color-grey-80"
                data="@icons/close.svg"
              />
            </template>
            {{ $t('Web.Deselect') }}
          </SlButton>
          <SlButton
            variant="secondary"
            color="grey"
            @click="handleProjectUnarchive"
          >
            <template #prepend>
              <icon
                class="fill-off size-16 color-grey-80"
                data="@icons/unarchive.svg"
              />
            </template>
            {{ $t('Web.Project.Unarchive') }}
          </SlButton>
          <SlButton
            color="accent-red"
            @click="handleProjectDelete"
          >
            <template #prepend>
              <icon
                class="fill-off size-16 color-white-stroke"
                data="@icons/trash.svg"
              />
            </template>
            {{ $t('Web.Project.Delete') }}
          </SlButton>
        </template>
      </SlSelectionInfo>
    </div>
    <div
      v-if="archivedProjects.length"
      class="subpage__actions"
    >
      <SlSearchInput
        :value="search"
        class="w-280"
        @submit="handleSearch"
      />
      <SlDropdown placement="bottom-start">
        <template #target="{ open }">
          <SlButton
            variant="secondary"
            color="grey"
            size="md"
            icon
            @click="open"
          >
            <icon
              data="@icons/vertical-dots.svg"
              class="size-20 color-grey"
            />
          </SlButton>
        </template>
        <template #content>
          <SlDropdownOption>
            <template #prepend>
              <SlCheckbox
                id="bulk-edit"
                v-model="bulkEdit"
                toggle
              />
            </template>
            <label for="bulk-edit">
              {{ $t('Web.Project.BulkEdit') }}
            </label>
          </SlDropdownOption>
        </template>
      </SlDropdown>
    </div>
    <div class="subpage__table-wrapper">
      <div class="subpage__table">
        <SlTable
          id="archive-project-list"
          ref="archiveProjectsTable"
          :headers="tableConfig.archived.headers"
          :rows="archivedProjects"
          :unique-key="tableConfig.archived.uniqueKey"
          header-unique-key="key"
          header-name-key="text"
          :hidden-columns-keys="['id', 'actionsFlags']"
          :initial-col-sizes="initialColSizes"
          :max-height="1100"
          :row-height="40"
          :col-initial-width="40"
          :col-resize="false"
          search-by="name"
          :inner-no-data="!!search"
          :selectable="bulkEdit"
          list
          @checkbox-toggle="handleRowChecked"
          @checkbox-toggle-all="handleAllRowsChecked"
        >
          <template #header-_actionsFlags-last />

          <template #name="slotScope">
            <div class="sl-table__cell-text">
              {{ slotScope.cellValue }}
            </div>
          </template>
          <template #actionsFlags-last="slotScope">
            <ProjectActionsDropdown
              v-if="+slotScope.cellValue"
              :project="slotScope.row"
              :actions="+slotScope.cellValue"
            />
          </template>

          <template #no-data>
            <SlNoData>
              <template #image>
                <icon
                  :data="noDataMeta.icon"
                  :class="{
                    'illustration-md': !search,
                    'size-48': search,
                    'fill-off': search
                  }"
                />
              </template>
              <template #title>
                {{ noDataMeta.title }}
              </template>
              <template #text>
                {{ noDataMeta.text }}
              </template>
            </SlNoData>
          </template>
        </SlTable>
      </div>
    </div>
  </ContentBlock>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import ProjectActionsDropdown from './ProjectActionsDropdown.vue';
import { modal } from '@/mixins/modal';
import { projectTableConfig } from '@/config/project';
import modalIds from '@/config/shared/modalsId.config';

export default {
  name: 'ArchiveList',
  components: {
    ProjectActionsDropdown
  },
  mixins: [modal],
  data() {
    return {
      search: '',
      bulkEdit: false,
      selectedRowIds: []
    };
  },
  computed: {
    ...mapState({
      projects: (state) => state.manageProjects.projectList
    }),
    archivedProjects() {
      return this.projects?.archived || [];
    },
    tableConfig() {
      return projectTableConfig(this);
    },
    noDataMeta() {
      if (this.search) {
        return {
          icon: require('@icons/style_no_search_results_double.svg'),
          title: this.$t('Web.NoData.SearchTitle'),
          text: this.$t('Web.NoData.SearchText')
        };
      }

      return {
        icon: require('@icons/illustration/no-projects.svg'),
        title: this.$t('Web.Project.Label.Empty'),
        text: this.$t('Web.Project.Label.EmptyDesc')
      };
    },
    initialColSizes() {
      return [`calc(100% - ${this.bulkEdit ? 48 : 0}px)`, '40px'];
    },
    selectedRowsCount() {
      return this.selectedRowIds.length;
    }
  },
  watch: {
    bulkEdit() {
      this.resetSelected();
    }
  },
  methods: {
    ...mapActions({
      projectUnarchive: 'manageProjects/handleProjectUnarchive',
      projectDelete: 'manageProjects/handleProjectDelete'
    }),
    getBulkEditPayload() {
      return {
        id: [...this.selectedRowIds]
      };
    },
    handleProjectDelete() {
      this.showModal(modalIds.SL_CONFIRM_MODAL, {
        title: this.$t('Web.Modals.Delete.Title', {
          1: this.$t('Web.Pluralization.Item.val', {
            p1: this.$tc('Web.Pluralization.Item.p1', this.selectedRowsCount, { 1: this.selectedRowsCount })
          })
        }),
        text: this.$t('Web.Modals.Delete.DescriptionBulk', {
          1: this.$tc('Web.Pluralization.ItThem', this.selectedRowsCount)
        }),
        icon: 'style_warning_double',
        confirmText: this.$t('Web.Modals.ActionConfirm.ButtonDelete'),
        confirmCallback: () => {
          this.projectDelete(this.getBulkEditPayload());
          this.resetSelected();
        }
      });
    },
    handleProjectUnarchive() {
      this.projectUnarchive(this.getBulkEditPayload());

      this.resetSelected();
    },
    handleSearch(query) {
      this.search = query;

      this.$refs.archiveProjectsTable.submitLocalSearch(query);
    },
    resetSelected() {
      this.$refs.archiveProjectsTable?.resetSelected();

      this.selectedRowIds = [];
    },
    handleRowChecked({ checked, id }) {
      if (checked) {
        this.selectedRowIds.push(id);
      } else {
        this.selectedRowIds = this.selectedRowIds.filter((rowId) => rowId !== id);
      }
    },
    handleAllRowsChecked({ ids }) {
      this.selectedRowIds = ids;
    }
  }
};
</script>
